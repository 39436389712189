import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import WhoWeAre from '../../components/careers/WhoWeAre'
import PartOfLenses from '../../components/careers/PartOfLenses'
import BeYou from '../../components/careers/BeYou'
import HeaderSEO from '../../components/partials/HeaderSEO'
import { Helmet } from 'react-helmet'

interface PageSEO {
  description: Description
  keywords: Keywords
  title: Title
  oGimage: OGimage
}

interface OGimage {
  fluid: Fluid
}
interface Fluid {
  src: string
}
interface Keywords {
  keywords: string
}
interface Description {
  description: string
}
interface Title {
  title: string
}

interface StateProps {
  LocationState: LocationData
}

interface LocationData {
  roleLocation: string
  link: string
}

const uXArchitect: React.FC<StateProps> = () => {
  const [locationData, setLocationData] = useState<LocationData>({
    roleLocation: 'London, UK',
    link: 'https://apply.workable.com/lensesio/j/7A70F130E4/',
  })
  const { roleLocation, link } = locationData

  useEffect(() => {
    var url_string = window.location.href
    var url = new URL(url_string)
    var location = url.searchParams.get('location')
    if (location == 'gr') {
      setLocationData({
        roleLocation: 'Athens, GR',
        link: 'https://apply.workable.com/lensesio/j/9B41B1ECB1/',
      })
    }
  }, [])

  // Matching to HeaderSEO format
  const pageSEO: PageSEO = {
    description: {
      description:
        'We are looking for a UX Architect in London and Athens. Apply to join our team!',
    },
    keywords: {
      keywords: 'UX Architect, London,Athens,Lenses careers,Hiring,Job opening',
    },
    oGimage: {
      fluid: {
        src: '//images.ctfassets.net/tnuaj0t7r912/4Ilca8Bu6o99CFTMiy7EUk/7f3c5315e50287778c2d5ee9d028d2f3/ux-architect.jpeg',
      },
    },
    title: {
      title: 'UX Architect',
    },
  }

  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <Helmet>
        <link rel="canonical" href="https://lenses.io/careers/ux-architect/" />
      </Helmet>
      <div className=" bg-dark py-5 my-5"></div>
      <br />
      <br />
      <div className="container-1 mb-5">
        <div className="card offset-md-2 col-md-8 col-sm-12">
          <div className="card-body pl-4 pr-4">
            <section className="section section--header">
              <div className="roll-button">
                <a
                  href="/careers/#openpositions"
                  className="bg-red text-white btn-xs mr-0 border-0 all-jobs-negative-margin"
                >
                  <i className="fa fa-arrow-left mr-1"></i>
                  <span data-hover="All jobs">All jobs</span>
                </a>
              </div>
            </section>
          </div>
          <h1 className="h3-like">UX Architect</h1>
          <p className="meta">{roleLocation} </p>
          <section className="section section--text">
            <WhoWeAre />

            <h5 className="pt-3">What your day-to-day looks like:</h5>
            <p>
              The UX team sits at the intersection of product design, business,
              and engineering to deliver usable and valuable solutions to our
              global customers. We are looking for team members who are
              passionate about turning real-time data flowing through business,
              data sources, apps and processes into experiences so different
              types of personas can access, interact and take actions.
            </p>
            <p>
              As a UX Architect you will focus on the structure and flow of our
              product. You will partner with business experts and product
              managers to understand how the end user interacts with the apps,
              and then design and develop the structure, layouts and flows to
              make the execution of daily work easier and more successful. By
              using your UX skills in wireframing and prototyping, user research
              and user testing, organizing information and layout, as well as
              analytics and metrics - you will drive innovation and explore new
              ways of how to solve problems for our end users and their use
              cases. Your mission will be to identify user problems and drive
              the creation of effective user-interfaces and experiences, and you
              will work in cross-functional teams with product managers and
              engineers.
            </p>

            <ul className="pl-4 ">
              <li>
                Continuously raise the bar of quality in experience and design
              </li>
              <li>
                Set strategy on product experience and design, collect and
                analyse feedback from users withqualitative and quantitative
                metrics
              </li>
              <li>
                Work with customers and stakeholders to give and solicit
                feedback
              </li>
            </ul>

            <h5 className="pt-3">What you will bring:</h5>

            <ul className="pl-4 ">
              <li>
                A degree in Human-Computer-Interaction (HCI), Information
                Design, Engineering, Psychology or similar
              </li>
              <li>
                Strong proven experience in similar position, designing software
                products or complex enterpriseapplications ( & understand
                on-prem software ideocracies), ideally with experience in the
                field of dataengineering or data related projects
              </li>
              <li>
                Ability to turn complex business requirements into great
                experiences, easy to understand and use
              </li>
              <li>
                Can use prototyping as a method to demonstrate your ideas,
                comfortable iterating fast on low fidelity
              </li>
              <li>
                Collaborate and review new designs, and work closely with
                engineering teams to understand problemsand create solutions
              </li>
              <li>
                Knowledge on how to gather needs and validate concepts with
                customers
              </li>
              <li>Strong user-centric mindset and approach to design</li>
              <li>
                Ability to translate business goals and user needs into a
                constantly evolving set of best practice UXguidelines geared to
                giving our customers an optimum experience
              </li>
              <li>
                Solid experience in data visualization and information
                architecture
              </li>
              <li>
                An active contribution to the design system, scoping, and
                creating new components
              </li>
              <li>A portfolio that demonstrates your UX skills and process</li>
              <li>
                Use of tools like Sketch, Abstract, Zeplin, so any similar
                experience is required and high-levelunderstanding of HTML, CSS,
                Javascript, atomic design principles
              </li>
              <li>
                Enjoyment working with remote teams and embrace a dynamic
                working environment that isundergoing a high pace of
                transformation change.
              </li>
            </ul>

            <PartOfLenses />
          </section>

          <section>
            <BeYou link={link} />
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default uXArchitect
